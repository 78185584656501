import React from 'react'

function Map() {
    return (
        <>
         <div className="google-map">

            <iframe  title="My Address" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.127863117638!2d75.78624957482354!3d11.252003250242065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba659415b41aaab%3A0xa23ce62ac341ad5!2sYeso%20Building!5e0!3m2!1sen!2sin!4v1699267495857!5m2!1sen!2sin" ></iframe>

        </div>   
        </>
    )
}

export default  Map
