import React from 'react'
import bg_vide from '../../videos/bigway.mp4'
function About1() {
    
    return (
        <>
            <section id="about" className="bg-non-fixed">
            {/*<video autoPlay loop muted className="bg_video">
                <source src={bg_vide} type="video/mp4"></source>
    </video>*/}
            <div className="color-scheme-2 "></div>
            <div className="bg-50-r"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About</h1>
                                        <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                        <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                        Bluegenie IT solutions, an IT firm with a base in Bahrain and a committed technical team in India, guarantees unrivaled quality in every service, every step of the way, by utilizing committed teams who collaborate directly with customers. We offer a variety of support services, such as new technology website design from entry level to high end, social media marketing that helps clients meet their expectations in engaging with more customer audience, and user-friendly customized software for achieving our clients' business objectives in a timely and professional manner. The company is run by a group of exceptionally bright, 
                                        technically adept, and imaginative individuals
                                        </p>
                                    </article>
                                </div>
                            </div> 

                        </div> 
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="pad-l-45 pad-r-45 block">

                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                                <h4 className="widget-title"></h4>
                                <p>
                                We believe that design is more than just aesthetics; it's about creating impactful experiences. Our team of talented designers combines artistry with strategy to deliver visually stunning and functional designs that captivate your audience.
                                </p>
                            </div>
                            
                            <div className="widget-block wow fadeInDown animated" data-wow-duration="1.5s">
                            <div className="clearfix"></div>
                                
                                <div className="progress">
                                    <div className="progress-bar" style={{width: "0%"}} >

                                    </div>
                                    <span className="skill-value">Graphic Design <span></span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "0%"}}>

                                    </div>
                                    <span className="skill-value">Web Design <span></span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "0%"}}>

                                    </div>
                                    <span className="skill-value">Web Development<span></span></span>
                                </div>
                                
                                <div className="progress">
                                    <div className="progress-bar"  style={{width: "0%"}}>

                                    </div>
                                    <span className="skill-value">Digital Marketing <span></span></span>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </>
    )
}

export default About1
