import React from 'react'

function Welcome1() {
    return (
        <section>
            <div className="color-scheme-dark-2 ">
                
                <div className="bg_object1 lax lax_preset_fadeIn:50:100 lax_preset_spin:4000:270  lax_preset_slideY:2500:700"></div>
                <div className="container-fluid">
                    <div className="row  justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="pad-l-45 pad-r-45">
                                <div className="section-block-title">
                                    <div className="section-title">   
                                        <article className="section-title-body">
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">OUR MISSION</h1>
                                            <div className="section-col wow fadeInUp animated" data-wow-duration="2.0s"></div>
                                            <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                            At Bluegenie, we are committed to delivering innovative IT solutions that empower businesses to thrive in the digital age. Our mission is to provide top-notch IT services that enhance efficiency, security, and competitiveness for our clients.
                                            </p>
                                        </article>
                                    </div>
                                </div>  

                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome1
