import React from 'react'
const services = [
    {
        _id:'001',
        classIcon:'ion-ios-pie-outline',
        title:'GRAPHIC DESIGNING',
        text:'From logos and branding to marketing collateral, our graphic design experts breathe life into your visual identity. We design responsive, mobile-first websites and apps that engage users and drive conversions.'
    },
    {
        _id:'002',
        classIcon:'ion-ios-printer-outline',
        title:'DIGITAL MARKETING',
        text:'In todays digital landscape, having a strong online presence is crucial for business success. We offer comprehensive digital marketing services that help you connect with your target audience, increase brand visibility, and achieve your marketing goals.'
    },
    {
        _id:'003',
        classIcon:'ion-ios-reverse-camera-outline',
        title:'WEB DEVELOPMENT',
        text:'Your website is often the first point of contact between your business and your audience. At Bluegenie, we specialize in web development services that create a powerful online presence for your brand, blending creativity and functionality seamlessly.'
    },
    {
        _id:'004',
        classIcon:'ion-ios-telephone-outline',
        title:'SOFTWARE TESTING',
        text:'Quality assurance is paramount in software development. At Bluegenie, we offer comprehensive software testing services to identify and rectify issues, ensuring your software solutions are reliable and user-friendly.'
    },
    {
        _id:'005',
        classIcon:'ion-ios-flask-outline',
        title:'UI & UX Design',
        text:'We craft intuitive and user-friendly interfaces that enhance user experiences across digital platforms. Our UX designers optimize your digital products to ensure they are user-centric, efficient, and enjoyable.'
    },
    
]
function Service1() {
    return (
        <>
          <section  id="services"  className="bg-non-fixed">
            <div className="bg-inner-dark2"></div>
            <div className="bg-50-l"></div>
            
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-sm-12 col-xs-12 order-md-1 justify-content-center d-flex align-items-center">
                        <div className="pad-l-45 pad-r-45">
                            <div className="section-block-title">
                                <div className="section-title">   
                                    <article className="section-title-body mobile-mb-0">
                                        <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Services</h1>
                                        <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                        <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s">
                                        Consistently exceeding expectations, our services ensure successful deliveries that speak louder than words.
                                        </p>
                                        <div className="h25"></div>
                                        
                                    </article>
                                </div>  
                            </div>     
                        </div>

                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                        <div className="pad-l-45 pad-r-45 block">
                            <div className="row">
                                {services.map((service)=>(
                                    <div className="col-lg-12 col-xxl-6" key={service._id}>
                                    <div className="block-feature">
                                        <i className={service.classIcon}></i>
                                        <a href="/">{service.title}</a>
                                        <p>
                                        {service.text}
                                        </p>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>  
        </>
    )
}

export default Service1
