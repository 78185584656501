import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

function Lightbox() {
    return (
        <>
        <SimpleReactLightbox>
            <SRLWrapper>
            <div className="row  gx-5">
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/preview/blog1.jpg">
                            <img  className="img-fluid" alt="Diet Consulting Firm" src="img/preview/blog1.jpg"/>
                        </a>
                        <span className="single-portfolio-title">Swasth Diet</span>
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/preview/blog20.jpg">
                            <img  className="img-fluid" alt="Co-working Space" src="img/preview/blog20.jpg"/>
                        </a>
                        <span className="single-portfolio-title">Yeso Coworking Spaces</span>
                    </div>
                </div>
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/preview/blog18.jpg">
                            <img  className="img-fluid" alt="Event Management Company" src="img/preview/blog18.jpg"/>
                        </a>
                        <span className="single-portfolio-title">Flying Lantern ENtertainment Pvt Ltd.</span>
                    </div>
                </div> 
                <div className="col-xl-6 col-md-12">
                    <div className="single-portfolio-block">
                        <a href="img/preview/blog3.jpg">
                            <img  className="img-fluid" alt="Leading Supermarket chain in Bahrain" src="img/preview/blog3.jpg"/>
                        </a>
                        <span className="single-portfolio-title">TRIO MART</span>
                    </div>
                </div> 
            </div>
            </SRLWrapper>
        </SimpleReactLightbox> 
        </>
    )
}

export default Lightbox
